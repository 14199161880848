.modal {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  .modalBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
  }
}

.addContainer,
.editContainer {
  z-index: 3;
  background: var(--main-color);
  // display: inline-block;
  width: 19.5rem;
  // top: calc(100% + 1rem);
  position: relative;
  margin: 2.5rem auto 0;
  border-radius: 5px;
	padding: 1rem;
	text-align:center;
  & .buttonGroup {
		display: inline-flex;
    border-radius: 5px;
    overflow: hidden;
    background: var(--button-group-color);
    // box-shadow: inset 0 0px 3px rgb(99, 99, 99);
    & input {
      background: transparent;
      border-radius: 5px;
    }
    & .active {
      background: var(--theme-color);
    }
  }
  & > span {
    font-size: 1em;
  }
  & .addSubmit,
  & .remove {
    font-size: 1em;
    width: 100%;
  }
  & .remove {
    margin-top: 1em;
    background: var(--secondary-theme-color);
  }
  & > div {
    margin-bottom: 1rem;
  }
  & .dueDateAndTime {
    display: flex;
  }
  & .inputMotion {
    position: relative;
    & .inputTitle {
      position: absolute;
      font-size: 1.2em;
      color: var(--main-font-color);
      top: 1em;
      left: 0;
      transition: all ease 0.08s;
      pointer-events: none;
    }
    & input {
      font-size: 1.2em;
      padding-top: 1em;
      font-weight: bold;
      border: none;
      outline: none;
      // border-bottom: 1px var(--secondary-font-color) solid;
      width: 100%;
      background: inherit;
      &:focus,
      &:valid {
        // border-bottom: 1px var(--main-color) solid;
        & + .inputTitle {
          top: 0;
          font-size: 0.8em;
          color: var(--secondary-font-color);
        }
      }
    }
    & .dateInput {
      color: transparent;
      text-shadow: 0 0 0 var(--main-font-color);
      cursor: pointer;
    }
  }
}
