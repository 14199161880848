.todoList {
  margin-bottom: 1em;
  position: relative;
  border-radius: 5px;
  background: var(--secondary-color);
  padding: calc(0.8rem + 5px) 0 0.8rem;
  &:last-child .todoCheck:nth-last-child(-n + 2) .optionsMenu {
    bottom: 0;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    & .listOptions .options {
      display: inline;
    }
  }
  & h2 {
    font-size: 1.1em;
    display: inline;
    padding-left: 0.8rem;
  }
  & .todoForm {
    display: inline;
    & input {
      width: 80%;
      font-size: 1.1em;
      border: none;
      outline: none;
      background-color: inherit;
      font-weight: bold;
    }
  }

  & p {
    display: inline-block;
    width: calc(100% - 5.4rem);
    margin: 0 0.8rem;
  }
  & .listDue {
    display: inline;
    font-size: 0.8rem;
    color: gray;
    margin-left: 0.6rem;
  }
  & ul {
    margin-top: 0.8rem;
  }
  & .collapsed {
    overflow: hidden;
    height: 0px;
    margin: 0;
  }
  & form {
    position: relative;
    margin: 0 0.8rem;
    // border-bottom: 1px var(--main-font-color) solid;
    & .addTodo {
      background: inherit;
      border: none;
      outline: none;
      font-size: 1em;
      padding: 0 1.2em;
      width: 100%;
      &::-webkit-input-placeholder {
        color: var(--main-font-color);
      }
      &::-moz-placeholder {
        color: var(--main-font-color);
      }
      &:-ms-input-placeholder {
        color: var(--main-font-color);
      }
      &::-ms-input-placeholder {
        color: var(--main-font-color);
      }
      &::placeholder {
        color: var(--main-font-color);
      }
    }
    & .submitTodo {
      background: transparent;
      color: var(--theme-color);
      font-weight: bold;
      padding: 0 0.2rem;
      position: absolute;
      height: 1em;
    }
  }
  & .listOptions {
    float: right;
    padding-right: 0.8rem;
    & .options {
      & svg path {
        fill: gray;
      }
    }
    & .collapseButton {
      margin-left: 1em;
      transition: transform 0.2s ease-out;
      transform: rotate(-180deg);
      & svg path {
        fill: var(--main-font-color);
      }
    }
    & .flipped {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
    }
    & .collapseButton,
    & .options {
      width: 1rem;
      font-size: 1em;
      cursor: pointer;
    }
    & .optionsMenu {
      background: var(--options-background-color);
      padding: 0.6rem;
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      border-radius: 5px;
      display: inline-block;
      & button {
        display: block;
        white-space: nowrap;
        margin: 0.125rem 0;
        &:hover {
          cursor: pointer;
          color: var(--theme-color);
        }
      }
      & form {
        margin: 0;
        & textarea {
          width: 100%;
          outline: none;
          border: none;
          background: var(--main-color);
          border-radius: 5px;
          padding: .2rem;
        }
      }
      & .projectList {
        padding-left: 0.4rem;
      }
    }
  }
  & .progressDiv {
    position: absolute;
    width: 100%;
    height: 5px;
    overflow: hidden;
    top: 0;
    left: 0;
    border-radius: 5px 5px 0 0;
    & progress {
      position: absolute;
      top: 0;
      left: 0;
      background-color: inherit;
    }
    & progress::-webkit-progress-bar {
      background-color: inherit;
      border-radius: 5px;
    }
  }
}
