.calendar {
	@include container;
	background: var(--secondary-color);
	& .sheet {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		text-align: center;
		& input {
			width: 2.5em;
			height: 2.5em;
			border-radius: 50%;
			font-size: 1em;
			color: var(--main-font-color);
			background: transparent;
			margin: auto;
			&:hover {
				background: var(--theme-color);
				color: var(--main-color);
			}
		}
	}
}

.calendarInput {
	overflow: hidden;
	// transition: max-height linear .3s .3s, max-width linear 0.3s;
	& .month {
	}
	& .sheet {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		text-align: center;
		& .day {
			color: var(--main-font-color);
		}
		& input {
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 50%;
			font-size: 1rem;
			color: var(--main-font-color);
			background: transparent;
			margin: auto;
			padding: 0;
			line-height: 2.5rem;
			&:hover {
				background: var(--theme-color);
				color: var(--main-color);
			}
		}
		& span {
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 50%;
			font-size: 1rem;
			color: var(--secondary-font-color);
			margin: auto;
			display: block;
			line-height: 2.5rem;
		}
	}
	& .disabled {
		cursor: default;
		opacity: 0;
		pointer-events: none;
	}
	& .calendarControls {
		display: flex;
		justify-content: space-between;
	}
}
