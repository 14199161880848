.App {
  height: 100vh;
  width: 100vw;
  // overflow: hidden;
  overflow: auto;
}

.content {
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
