.todoCheck {
  margin: 0.4em 0.8rem;
  position: relative;
  // &:nth-last-child(-n + 3) .todoOptions .optionsMenu {
  //   bottom: 0;
  // }
  &:hover {
    // background: linear-gradient(to left, lightgray 0%,transparent 90%);
    & .todoOptions {
      display: inline-block;
    }
  }
  & input[type='checkbox'] {
    opacity: 0;
    cursor: pointer;
    // margin-right: 0.4rem;
    height: 1em;
    width: 1em;
    position: absolute;
    top: 0.2em;
    z-index: 2;
    &:checked {
      & + label::after {
        content: url(../../../images/checkmark.svg);
        color: var(--theme-color);
      }
      & + label {
        text-decoration: line-through;
        color: var(--secondary-font-color);
      }
    }
  }
  & label {
    word-break: break-word;
    margin-left: 1.4rem;
    cursor: text;
  }
  position: relative;
  & label::before,
  & label::after {
    display: inline-block;
    height: 1em;
    width: 1em;
    position: absolute;
    top: 0.2em;
    border-radius: 2px;
    pointer-events: none;
  }
  & label::before {
    background: var(--checkbox-color);
    content: '';
    left: 0rem;
  }
  & label::after {
    content: none;
    left: 0.3rem;
  }

  & .todoOptions {
    position: absolute;
    right: 0;
    & button {
      cursor: pointer;

      &:hover {
        & svg path {
          fill: var(--secondary-theme-color);
          transition: fill 0.3s linear;
          -webkit-transition: fill 0.3s linear;
          -moz-transition: fill 0.3s linear;
          -ms-transition: fill 0.3s linear;
          -o-transition: fill 0.3s linear;
        }
      }
    }
    & .optionsMenu {
      background: var(--main-color);
      padding: 0.6rem;
      position: absolute;
      z-index: 2;
      right: 0;
      // top: 0;
      border-radius: 5px;
      display: inline-block;
      & button {
        text-align: right;
        display: block;
        white-space: nowrap;
        width: 100%;
        cursor: pointer;
        &:hover {
          cursor: pointer;
          color: var(--theme-color);
        }
      }
      & .projectList {
        padding-left: 0.4rem;
      }
    }
    & svg path {
      fill: gray;
    }
  }
  & .todoForm {
    border: none;
    display: inline;
    margin: 0;
  }
  & .editInput {
    outline: none;
    border: none;
    overflow: visible;
    resize: none;
    background-color: transparent;
    margin-left: 1.4rem;
    // border-bottom: solid black 1px;
    width: calc(100% - 2.6rem)
  }
  & .todoDue {
    display: inline;
    font-size: 0.8rem;
    color: gray;
    margin-left: 0.6rem;
  }
}
