* {
  margin: 0;
  padding: 0px;
  font-size: 14px;
  box-sizing: border-box;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-font-color);
}

body {
  height: 100vh;
  overflow: hidden;
}

.home {
  background: var(--main-color) url(../../../images/background.svg) center
    bottom/cover no-repeat;
  height: 100vh;
  padding-top: 4rem;
  & h1 {
    font-size: 4em;
    text-align: center;
  }
  & p {
    font-size: 1.4em;
    text-align: center;
  }
  & ul {
    display: flex;
    justify-content: center;
    align-items: center;
    & li {
      display: inline-block;
      font-size: 1.2em;
      margin: 0 1rem 0.2rem;
      font-weight: bold;
      & img {
        width: 1rem;
      }
    }
  }

  & .links {
    margin: 2.5rem 0;
    text-align: center;
    & .link,
    .app {
      font-size: 1.2em;
      text-decoration: none;
    }
    & .app {
      background-color: var(--theme-color);
      color: white;
      border: none;
      border-radius: 5px;
      padding: 0.2em 0.4em;
      cursor: pointer;
    }
    & .link {
      margin-left: 2rem;
      border-bottom: 1px solid var(--main-font-color);
      &:hover {
        color: var(--theme-color);
        border-color: var(--theme-color);
      }
    }
  }
}
