.taskDetailContainer {
  overflow: scroll;
  position: relative;
  & .projectInfo {
    display: inline-block;
    cursor: text;
    width: 100%;
    margin-bottom: 1rem;
  }
  & h1 {
    margin-bottom: 0.4rem;
    font-size: 1.6em;
  }
  & span {
    margin-bottom: 0.4rem;
    display: block;
  }
  & .addListForm {
    width: 100%;
    & input {
      &[type='text'] {
        width: 70%;
        margin: 0;
      }
      &[type='submit'] {
        width: 30%;
      }
    }
  }
  & .listText {
    background: var(--main-color);
    border: 2px var(--theme-color) solid;
    border-right: none;
    border-radius: 5px 0 0 5px;
    margin: 1rem 0;
    outline: none;
    padding: calc(0.2em - 2px) 0.4em;
    & + input {
      border-radius: 0 5px 5px 0;
      -webkit-border-radius: 0 5px 5px 0;
      -moz-border-radius: 0 5px 5px 0;
      -ms-border-radius: 0 5px 5px 0;
      -o-border-radius: 0 5px 5px 0;
    }
  }
  .editContainer {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 4;
  }
  .modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }
}
