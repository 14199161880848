.toolbar {
  // height: 2.5rem;
  width: 100vw;
  position: relative;
  background: var(--theme-color);
  & .addBtn {
    margin: 0 auto;
    display: block;
  }
  & button {
    font-size: 2em;
    line-height: 2.5rem;
    outline: none;
    display: inline;
    & svg path {
      fill: black;
    }
    &:hover {
      cursor: pointer;
    }
  }
  & .user {
    display: table;
    position: absolute;
    right: 1rem;
    top: 0;
    & span {
      padding: 0;
      margin: 0;
      padding-right: 1rem;
      color: black;
      font-weight: bold;
      display: table-cell;
      vertical-align: middle;
    }
  }
}
