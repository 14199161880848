.signIn,
.signUp {
  form {
    margin-bottom: 1em;
    & label {
      display: block;
    }
    & input[type='text'],
    & input[type='email'],
    & input[type='password'] {
      margin-bottom: 1rem;
      font-size: 1.2rem;
      color: black;
      border: none;
      border-radius: 5px;
      width: 100%;
      outline: none;
      padding: 0.2em 0.2em;
      &:focus {
        outline: 5px solid rgb(0, 200, 150);
      }
    }
    & input[type='submit'] {
      width: 100%;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 1em;
    & li {
      margin: none;
      padding: none;
    }
  }

  input[type='button'] {
    width: 100%;
  }

  .invalid {
    color: var(--secondary-theme-color);
  }

  .valid {
    display: none;
  }

  span {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  #back {
    background-color: var(--secondary-theme-color);
    margin-bottom: 1em;
  }
}
