.today {
	margin-bottom: 1rem;
	white-space: normal !important;
	& span {
		display: block;
		margin: 0;
		font-size: 0.8em;
		margin: 0.4em 0;
		font-weight: bold;
		text-transform: uppercase;
	}
	& .dueToday span {
		color: var(--theme-color);
	}
	& .dueLate span {
		color: rgb(255, 100, 50);
	}
}
