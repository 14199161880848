.menu {
  position: absolute;
  height: 100vh;
  width: 20rem;
  z-index: 3;
  right: 0;
  top: 0;
  background-color: var(--secondary-color);
  padding: 1rem 0.5rem;
  & input[type='button'] {
    width: 100%;
    margin-bottom: 1rem;
  }
  & span {
    display: block;
  }
  & .name {
    font-size: 1.4em;
    font-weight: bold;
  }
}
