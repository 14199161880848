.tasks {
  @include container;
  display: flex;
  background: var(--main-color);
  overflow: hidden;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 3;
}

.taskContent {
  overflow: scroll;
  @media screen and (min-width: 768px) {
    display: grid;
    // flex: 1;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    height: 100%;
  }
}

.taskList > * {
  margin-bottom: 1rem;
}

.taskList {
  @media screen and (min-width: 768px) {
    overflow: scroll;
  }
}

.optionsBtn {
  // width: 1em;
  display: none;
  padding: 0 0.6rem;
  background: var(--secondary-color);
  margin-left: 0.4rem;
  border-radius: 5px;

  &:hover {
    color: var(--theme-color);
  }
  & .option {
    margin-right: 0.6rem;
    cursor: pointer;
    &:last-child {
      margin: 0;
      &:hover {
        & svg path {
          fill: var(--secondary-theme-color);
          transition: fill 0.3s linear;
        }
      }
    }
  }
  &:last-child {
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}

.boardBtn {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--main-margin);
  overflow: hidden;
  & .projectBtn {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    & progress {
      position: absolute;
      top: 0;
      left: 0;
      background-color: inherit;
    }
    & progress::-webkit-progress-bar {
      background-color: inherit;
      border-radius: 5px;
    }
  }
  & button {
    cursor: pointer;
  }
  &:last-child {
    margin-bottom: 0;
  }
  & .title {
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  & .due {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8em;
    margin: 0;
  }
  & .dueToday {
    color: var(--theme-color);
    font-weight: 600;
  }
  & .dueLate {
    color: rgb(255, 100, 50);
    font-weight: 600;
  }
  &:hover {
    .optionsBtn {
      display: flex;
      align-items: center;
    }
  }
}

.loading {
  height: 2.6em;
  background: linear-gradient(
    to right,
    var(--secondary-color) 40%,
    var(--main-color),
    var(--secondary-color) 60%
  );
  background-position: 150%;
  background-size: 200%;
  -webkit-animation: gradient 2s linear infinite;
  animation: gradient 2s linear infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 150%;
  }
  50% {
    background-position: -50%;
  }
  100% {
    background-position: -50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 150%;
  }
  50% {
    background-position: -50%;
  }
  100% {
    background-position: -50%;
  }
}
